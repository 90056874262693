<script>
import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  data() {
    return {
      selectedItemIndex: -1,
      deletedContent: {},
      modalCoverContent: false,
      //======= following 3 params are for permission validation ========
      /**
       * this is used to decide whether validation is required using canWrite()
       * this can be overridden in list and create vue (where BaseContent is used as mixin)
       */
      validateWrite: true,
      /**
       *  this is used if there is any additional route we've to pass in canWrite().. for example canWrite('phone_numbers');
       *  this can be overridden in list and create vue (where BaseContent is used as mixin)
       */
      additionalValidateRoute: null,
      /**
       * there are two possible values - read / write: default is write
       * in write mode, canWrite() is always validated. this would prevent user from entering to create or edit page.
       * in read mode, canWrite() is not validated. this would allow user to read form value. while using this mode,
       * we must not forget to hide update button using v-show.
       */
      viewMode: "write",
      sortColumnLabel: "Name",
      availableSortOptions: ["asc|desc", "old|new", "mod"],
      selectedItems: [],
      isContent: false,
      deleteConfirmationEvent: "open-delete-confirmation-dialog-box",
      checkInUseDialogEvent: "open-check-in-use-dialog-box-task",
      isAllSelected: false,
      contentViewType: "card",
      isContentInUseCount: 0
    };
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1
    },
    contentType: {
      type: String,
      required: false,
      default: ""
    },
    primaryKey: {
      type: String,
      required: false,
      default: "id"
    },
    formAction: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    sortByOptions() {
      return this.createSortOptions();
    },
    contentViewClasses() {
      let defaultClass = "content-" + this.contentType.toLowerCase() + "-list";
      if (this.contentViewType === "list") {
        return [defaultClass, "content-view-list"];
      } else {
        return [defaultClass, "content-view-card", "list-cards"];
      }
    },
    /**
     * get the confirm message based on the is in use count
     * @returns {*}
     */
    confirmContentInUseMessage() {
      // eslint-disable-next-line
      return __("Is currently in use by :useCount node:moreCount.", { useCount: this.isContentInUseCount, moreCount: this.isContentInUseCount > 1 ? "s" : ""});
    }
  },
  methods: {
    handleSelect(index, contentItem) {
      this.selectedItemIndex = index;
      this.$emit("select-item", index, contentItem);
    },

    handleClearSelection() {
      this.$emit("clear-selection");
    },

    handleClearAside() {
      this.$emit("clear-aside");
    },

    handleEdit(index, contentItem) {
      if (!_.isEmpty(contentItem)) {
        this.selectedItemIndex = index;
      }
      if (this.viewMode === "write") {
        // if view mode is write (default)
        // if (this.canAddEditOrDelete()) {
        // check if user has permission
        this.$emit("edit-item", index, contentItem); // open modal for edit.
        //   } else {
        //     this.showPermissionError(); // show error for not having permission
        //   }
      } else {
        this.$emit("edit-item", index, contentItem); // allow user to see form when view mode is read
      }
    },
    handleView(index, contentItem) {
      if (!_.isEmpty(contentItem)) {
        this.selectedItemIndex = index;
      }
      this.$emit("view-item", index, contentItem);
    },
    createContentItem(params) {
      if (this.canAddEditOrDelete()) {
        // if user has write permission
        this.handleOpenCreateModel(params);
      } else {
        // show error
        this.showPermissionError();
      }
    },

    handleImport(params) {
      if (this.canAddEditOrDelete()) {
        // if user has write permission
        this.handleOpenImportModel(params);
      } else {
        // show error
        this.showPermissionError();
      }
    },

    canAddEditOrDelete() {
      // if validation is required for write permission (default is true) and there is additional route to be validated
      if (this.validateWrite && this.additionalValidateRoute) {
        if (this.isContent) {
          return this.can("content." + this.additionalValidateRoute + ".write");
        }
        return this.canWrite(this.additionalValidateRoute);
      } else if (this.validateWrite) {
        return this.canWrite();
      } else {
        return true; // when validateWrite is set to false in component
      }
    },

    handleOpenCreateModel(params) {
      this.$emit("open-create-modal", params);
      EventBus.$emit("open-create-modal", params);
    },

    handleOpenImportModel(params) {
      this.$emit("open-import-modal", params);
      EventBus.$emit("open-import-modal", params);
    },

    showPermissionError() {
      this.$message({
        message: __("you do not have permission to perform this action."),
        type: "error"
      });
    },

    undoDelete() {
      let self = this;
      this.$message.closeAll();
      this.undoDeleteContent(this.deletedContent).then(() => {
        self.emitListChangedEvent();
        EventBus.$emit("list-changed");
        EventBus.$emit("canvas-changed");
      });
    },

    isOptionVisible(option) {
      return this.availableSortOptions.includes(option);
    },

    handleDelete(content, content_model, content_id) {
      if (this.canAddEditOrDelete()) {
        if (content_model && content_id) {
          EventBus.$emit(this.deleteConfirmationEvent, {
            content_model: content_model,
            content_id: content_id,
            content: content
          });
        } else {
          this.initiateDelete(content);
        }
      } else {
        this.showPermissionError();
      }
    },
    handleCheckItemInUse(content_model, content_id) {
      EventBus.$emit(this.checkInUseDialogEvent, {
        content_model: content_model,
        content_id: content_id
      });
    },
    handleDeleteNoUndo(content) {
      this.$confirm(
        __("This will permanently delete the API Key. Continue?"),
        __("Warning"),
        {
          confirmButtonText: __("OK"),
          cancelButtonText: __("Cancel"),
          type: "warning"
        }
      )
        .then(() => {
          if (this.canAddEditOrDelete()) {
            this.initiateDeleteNoUndo(content);
          } else {
            this.showPermissionError();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("Delete canceled")
          });
        });
    },

    restoreContent(content) {
      const self = this;
      content.is_archived = 0;
      self
        .updateContent(content)
        .then(() => {
          self.$message({
            type: "success",
            duration: 4000,
            showClose: true,
            message: __("Restored successfully")
          });
          EventBus.$emit("list-changed");
          this.$emit("handleCancel");
        })
        .catch(err => {
          console.log(err);
          self.$message({
            type: "error",
            message: err.message
          });
        });
    },

    initiateDeleteNoUndo(content, cb = () => {}) {
      const self = this;
      this.deletedContent = _.cloneDeep(content);
      self
        .deleteContentMethod(content)
        .then(() => {
          self.$message({
            type: "success",
            duration: 8000,
            showClose: true,
            message: __("Delete successful")
          });
          self.emitListChangedEvent();
          cb();

          EventBus.$emit("canvas-changed");

          this.$emit("handleCancel");
        })
        .catch(err => {
          console.log(err);
          self.$message({
            type: "error",
            message: err.message
          });
        });
    },

    initiateDelete(content, cb = () => {}) {
      const self = this;
      this.deletedContent = _.cloneDeep(content);
      self
        .deleteContentMethod(content)
        .then(() => {
          const h = this.$createElement;
          self.$message({
            type: "success",
            duration: 8000,
            showClose: true,
            message: h(
              "p",
              null,
              _.compact([
                h("span", null, __("Delete successful")),
                this.undoDeleteContent
                  ? h(
                      "el-button",
                      {
                        style:
                          "border:none; background: transparent; text-decoration:underline; position:absolute; right:15px; top:10px",
                        class: "plain",
                        on: {
                          click: this.undoDelete
                        }
                      },
                      "undo"
                    )
                  : null
              ])
            )
          });
          self.emitListChangedEvent();
          cb();

          EventBus.$emit("canvas-changed");

          this.$emit("handleCancel");
        })
        .catch(err => {
          self.$message({
            type: "error",
            message: err.message
          });
        });
    },

    emitListChangedEvent() {
      EventBus.$emit("list-changed");
    },

    initiateSafeDelete(content) {
      const self = this;
      self
        .safeDeleteContent(content)
        .then(() => {
          self.$message({
            type: "success",
            duration: 4000,
            showClose: true,
            message: __("Archived successful")
          });
          EventBus.$emit("list-changed");

          EventBus.$emit("canvas-changed");

          this.$emit("handleCancel");
        })
        .catch(err => {
          console.log(err);
          self.$message({
            type: "error",
            message: err.message
          });
        });
    },

    createSortOptions() {
      let options = [];
      _.map(this.sortByColumns, (column, index) => {
        let asc;
        let desc;
        switch (index) {
          case 0:
            if (this.isOptionVisible("asc|desc")) {
              asc = {
                value: `${column}:asc`,
                label: `${this.sortColumnLabel} A-Z`
              };

              options.push(asc);

              desc = {
                value: `${column}:desc`,
                label: `${this.sortColumnLabel} Z-A`
              };

              options.push(desc);
            }
            break;
          case 1:
            if (this.isOptionVisible("old|new")) {
              desc = {
                value: `${column}:asc`,
                label: __("Oldest")
              };

              options.push(desc);
              asc = {
                value: `${column}:desc`,
                label: __("Newest")
              };

              options.push(asc);
            }
            break;
        }
      });
      if (this.isOptionVisible("mod")) {
        options.push({
          value: "updated_at:desc",
          label: __("Recently modified")
        });
      }
      return options;
    },

    handleSelectMultiple(checked) {
      let self = this;
      return item => {
        const itemIndex = _.indexOf(self.selectedItems, item);
        if (checked && !itemIndex >= 0) {
          // add item
          self.selectedItems.push(item);
        }

        if (!checked && itemIndex >= 0) {
          // remove item
          self.selectedItems.splice(itemIndex, 1);
          if (_.isEmpty(self.selectedItems)) {
            self.handleClearSelection();
          }
        }

        self.$emit("update-multi-selected", this.selectedItems);
      };
    },
    /**
     * get the confirm title based on the content type
     * @param contentType
     * @returns {*}
     */
    confirmContentInUseTitle(contentType) {
      // eslint-disable-next-line
      return __("Are you sure you want to update this :contentType?", {contentType: contentType});
    },

    handleToggleSelectAll(contents, isAllSelected = null) {
      if (isAllSelected !== null && typeof isAllSelected === "boolean") {
        this.isAllSelected = isAllSelected;
      } else {
        this.isAllSelected = !this.isAllSelected;
      }

      if (this.isAllSelected === true) {
        this.selectedItems = [...contents];
      } else {
        this.selectedItems.splice(0, this.selectedItems.length);
        this.handleClearSelection();
      }
      this.$emit("update-multi-selected", this.selectedItems);
    },

    handleSwitchContentViewType(contentViewType) {
      if (contentViewType == "card" || contentViewType == "list") {
        this.contentViewType = contentViewType;
      }
    }
  },

  async created() {
    let self = this;

    // cannot $off this listener as if multiple components are created with this component as mixin
    // $off ing the listener from EventBus will make all the components rendered not to listen to this event,
    // which is not good

    EventBus.$on("modal-cover-content", value => {
      self.modalCoverContent = value;
    });

    EventBus.$on("empty-multi-selected-items", () => {
      self.selectedItems = [];
      self.$emit("update-multi-selected", self.selectedItems);
    });

    EventBus.$on("uncheck-all-items", () => {
      this.handleToggleSelectAll([], false);
    });

    EventBus.$on("switch-content-view-type", value => {
      this.handleSwitchContentViewType(value);
    });
  },

  watch: {
    id: {
      immediate: true,
      handler: function() {
        this.selectedItemIndex = this.id;
      }
    }
  },

  beforeDestroy() {
    EventBus.$off("empty-multi-selected-items");
    EventBus.$off("uncheck-all-items");
    EventBus.$off("switch-content-view-type");
  }
};
</script>

<style lang="scss">
.content-view-card {
  display: flex;
  justify-content: flex-start;
}
</style>
