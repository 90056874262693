<template>
  <div class="page-toolbar">
    <el-row class="page-toolbar-row" type="flex">
      <el-col
        v-if="showSearch"
        :sm="8"
        :xs="8"
        :md="8"
        :lg="showPagination ? 5 : 8"
        :xl="showPagination ? 5 : 8"
      >
        <el-form-item>
          <el-input
            v-model="search"
            :placeholder="__('Search')"
            @input="handleSearch"
            class="searchInput"
          >
            <img
              slot="suffix"
              :src="require('@/assets/icons/icon-search.svg')"
              class="input-icon"
          /></el-input>
        </el-form-item>
      </el-col>
      <slot name="advancedSearch" v-if="showSlot"> </slot>
      <el-col
        v-if="sortByOptions.length"
        :sm="7"
        :xs="7"
        :md="7"
        :lg="showPagination ? 4 : 7"
        :xl="showPagination ? 4 : 7"
        style="padding-left: 20px"
      >
        <el-select
          v-model="sortBy"
          :placeholder="__('SortBy')"
          default-first-option
          @change="handleSort"
          class="sortList"
        >
          <el-option
            v-for="item in sortByOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col
        v-if="showContentViewSwitcher"
        :md="7"
        :lg="showPagination ? 4 : 7"
        :xl="showPagination ? 4 : 7"
        class="hidden-md-only hidden-sm-and-down"
      >
        <div class="flex content-view-switcher" style="padding: 0 10px">
          <el-radio-group
            v-model="contentViewType"
            @change="handleSwitchContentViewType"
          >
            <el-radio-button label="card">
              <font-awesome-icon :icon="['fas', 'th']" />
            </el-radio-button>
            <el-radio-button label="list">
              <font-awesome-icon :icon="['fas', 'list-ul']" />
            </el-radio-button>
          </el-radio-group>
        </div>
      </el-col>
      <slot name="buttonGroup" v-if="showSlot"> </slot>
      <el-col
        :xl="15"
        class="hidden-lg-and-down"
        style="display: flex;justify-content: flex-end; flex: 1; align-self: flex-end"
        v-if="showPagination"
      >
        <el-form-item class="pagination-form-item">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size.sync="pageSizeToRequest"
            :pager-count="5"
            :layout="xlLayout"
            :total="total"
          >
          </el-pagination>
        </el-form-item>
      </el-col>
      <el-col
        :lg="15"
        class="hidden-xl-only hidden-md-only hidden-sm-and-down"
        style="display: flex;justify-content: flex-end; flex: 1; align-self: flex-end"
        v-if="showPagination"
      >
        <el-form-item class="pagination-form-item">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size.sync="pageSizeToRequest"
            :pager-count="5"
            :layout="lgLayout"
            :total="total"
          >
          </el-pagination>
        </el-form-item>
      </el-col>
      <el-col
        :md="10"
        class="hidden-lg-and-up hidden-sm-and-down"
        style="display: flex;justify-content: flex-end; flex: 1; align-self: flex-end"
        v-if="showPagination"
      >
        <el-form-item class="pagination-form-item">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size.sync="pageSizeToRequest"
            :pager-count="5"
            :layout="mdLayout"
            :total="total"
          >
          </el-pagination>
        </el-form-item>
      </el-col>
      <el-col
        :sm="10"
        :xs="10"
        class="hidden-md-and-up"
        style="display: flex;justify-content: flex-end; flex: 1; align-self: flex-end"
        v-if="showPagination"
      >
        <el-form-item class="pagination-form-item">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size.sync="pageSizeToRequest"
            :pager-count="5"
            :layout="smLayout"
            :total="total"
          >
          </el-pagination>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/EventBus";
import { mapState, mapGetters } from "vuex";
import ContentIntervalUpdater from "@/mixins/ContentIntervalUpdater";
import { isErrorDueToCancelledToken } from "@/utils/studio7ApiService";

export default {
  name: "PaginationToolbar",
  mixins: [ContentIntervalUpdater],
  props: {
    searchPrefill: {
      required: false,
      default: ""
    },
    advSearchPrefill: {
      required: false,
      default: ""
    },
    searchJoin: {
      required: false,
      default: "",
      type: String
    },
    showSearch: {
      required: false,
      default: true
    },
    showOnly: {
      required: false,
      default: "",
      type: String
    },
    searchOptions: {
      required: false,
      default: "",
      type: String
    },
    searchFields: {
      required: false,
      default: "",
      type: String
    },
    sortByOptions: {
      required: false,
      type: Array,
      default: () => []
    },
    defaultSortBy: {
      required: false,
      type: String,
      default: __("Newest")
    },
    contentApi: {
      required: true,
      type: Function
    },
    showPagination: {
      default: true,
      type: Boolean
    },
    showSlot: {
      default: false,
      type: Boolean
    },
    listChangedEventName: {
      type: String,
      default: ""
    },
    pageSizes: {
      required: false,
      type: Array,
      default: () => [15, 30, 45, 60]
    },
    pageSize: {
      required: false,
      type: Number,
      default: 15
    },
    folderEnabled: {
      type: Boolean,
      default: false
    },
    hide_system: {
      type: Boolean,
      default: false
    },
    showContentViewSwitcher: {
      type: Boolean,
      default: false
    },
    showTotal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: "",
      pageSizeToRequest: null,
      debounce: null,
      sortBy: "",
      currentPage: 1,
      total: 0,
      mounted: false,
      searchInRoot: "",
      contentViewType: "card"
    };
  },
  computed: {
    ...mapGetters("app", {
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination"
      // selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId,
      selectedTaskId: state => state.selectedTaskId
    }),
    xlLayout() {
      let self = this;
      return self.showTotal
        ? "total, sizes, prev, pager, next, jumper"
        : "sizes, prev, next, jumper";
    },
    lgLayout() {
      let self = this;
      return self.showTotal
        ? "total, sizes, prev, next, jumper"
        : "sizes, prev, next, jumper";
    },
    mdLayout() {
      let self = this;
      return self.showTotal ? "total, prev, next" : "prev, next";
    },
    smLayout() {
      return "prev, next";
    }
  },
  created() {
    if (this.sortByOptions.length) {
      const savedSortByLabel = window.localStorage.getItem(
        "studio7-pagination-sortByLabel"
      );
      let defaultSortBy = savedSortByLabel
        ? savedSortByLabel
        : this.defaultSortBy;
      let newestSortOption = _.find(this.sortByOptions, sortByOption => {
        return sortByOption.label === defaultSortBy;
      });
      this.sortBy = newestSortOption ? newestSortOption.value : "";
    }

    const savedPageSize = window.localStorage.getItem(
      "studio7-pagination-pageSize"
    );
    this.pageSizeToRequest = savedPageSize
      ? parseInt(savedPageSize)
      : this.pageSize;

    const savedContentViewType = window.localStorage.getItem(
      "studio7-pagination-contentViewType"
    );
    this.contentViewType = savedContentViewType ? savedContentViewType : "card";
    this.handleSwitchContentViewType(this.contentViewType);
  },
  mounted() {
    let event = this.listChangedEventName
      ? this.listChangedEventName
      : "list-changed";
    EventBus.$on(event, (newContent = null, additionalInfo = {}) => {
      this.currentPage = _.get(additionalInfo, "page", this.currentPage);
      // console.log(`emit event on ${event}`);
      this.fetchContentItems()
        .then(() => {
          if (newContent) {
            this.$emit("success", newContent);
          }
        })
        .catch(() => {});
    });

    EventBus.$on("fetch-content-items-in-folder", () => {
      // console.log("emit event on fetch-content-items-in-folder");
      this.fetchContentItems().catch(() => {});
    });

    EventBus.$on("search-in-root", value => {
      this.searchInRoot = value;
      this.handleSearch();
    });

    this.mounted = true;
  },
  beforeDestroy() {
    EventBus.$off(
      this.listChangedEventName ? this.listChangedEventName : "list-changed"
    );

    EventBus.$off("fetch-content-items-in-folder");
    EventBus.$off("search-in-root");
  },
  methods: {
    debounceFetchContents(wait) {
      if (this.debounce === null) {
        this.debounce = _.debounce(() => {
          // console.log("debounced fn call");
          this.fetchContentItems().catch(() => {});
        }, wait);
      }
      this.debounce();
    },

    /**
     * updateTotalAndPages
     * @param total
     * @param pages
     */
    updateTotalAndPages(total, pages) {
      this.total = total;
      let totalPages = pages;
      if (this.currentPage > totalPages) {
        this.currentPage = totalPages;
        // console.log("updateTotalAndPages");
        this.fetchContentItems().catch(() => {});
      }
    },

    handleSearch() {
      this.$emit("search", this.search);
      if (!_.isEmpty(this.search) || !_.isEmpty(this.searchInRoot)) {
        EventBus.$emit("update-content-title", __("Search results"), "search");
      }

      this.debounceFetchContents(500);
    },
    handleSort() {
      let sortBy = _.find(this.sortByOptions, sortByOption => {
        return sortByOption.value === this.sortBy;
      });
      if (sortBy && sortBy.label) {
        window.localStorage.setItem(
          "studio7-pagination-sortByLabel",
          sortBy.label
        );
      }
      // console.log("on sort change");
      this.fetchContentItems().catch(() => {});
    },
    fetchContentItems(notShowLoader = false) {
      // console.log("calling fetchContentItems");
      return new Promise((resolve, reject) => {
        const sortByParams = _.split(this.sortBy, ":", 2);
        if (!_.isEmpty(this.search) || !_.isEmpty(this.searchInRoot)) {
          let searchInRoot = this.searchInRoot.trim();
          let search = this.search.trim();
          // need to have handling for search text here and advanced search filters,
          // the backend will see the text not keyed in the search string and use that to search
          if (!_.isEmpty(search) && !_.isEmpty(searchInRoot)) {
            searchInRoot = `${search};${searchInRoot}`;
          }
          return this.contentApi({
            search: searchInRoot ? searchInRoot : search,
            per_page: this.pageSizeToRequest,
            page: this.currentPage,
            orderBy: sortByParams[0],
            sortedBy: sortByParams[1],
            show_only: this.showOnly,
            folder_id: this.selectedFolderId,
            task_id: this.selectedTaskId,
            searchOptions: this.searchOptions,
            searchFields: this.searchFields,
            searchJoin: this.searchJoin,
            hide_system: this.hide_system,
            notShowLoader
          })
            .then(data => {
              this.updateTotalAndPages(
                _.get(data, "meta.pagination.total", 0),
                _.get(data, "meta.pagination.total_pages", 1)
              );
              resolve(data);
            })
            .catch(err => {
              if (!isErrorDueToCancelledToken(err)) {
                this.$message({
                  type: "error",
                  message: err.message
                });
              }
              reject(err);
            });
        } else {
          return this.contentApi({
            per_page: this.pageSizeToRequest,
            page: this.currentPage,
            orderBy: sortByParams[0],
            sortedBy: sortByParams[1],
            show_only: this.showOnly,
            folder_id: this.selectedFolderId,
            task_id: this.selectedTaskId,
            searchOptions: this.searchOptions,
            searchFields: this.searchFields,
            hide_system: this.hide_system,
            notShowLoader
          })
            .then(data => {
              this.updateTotalAndPages(
                _.get(data, "meta.pagination.total", 0),
                _.get(data, "meta.pagination.total_pages", 1)
              );
              resolve(data);
            })
            .catch(err => {
              if (!isErrorDueToCancelledToken(err)) {
                this.$message({
                  message: err.message,
                  type: "error"
                });
              }
              reject(err);
            });
        }
      });
    },

    handleSwitchContentViewType(contentViewType) {
      window.localStorage.setItem(
        "studio7-pagination-contentViewType",
        contentViewType
      );
      EventBus.$emit("switch-content-view-type", contentViewType);
      // EventBus.$emit("uncheck-all-items");
    }
  },
  watch: {
    searchPrefill: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (!!newVal !== !!oldVal && newVal !== oldVal) {
          this.search = newVal;
          this.$nextTick(() => {
            // console.log("watcher for search prefill");
            this.fetchContentItems().catch(() => {});
          });
        }
      }
    },
    advSearchPrefill: {
      handler: function(val) {
        this.searchInRoot = val;
        this.$nextTick(() => {
          // console.log("watcher for adv search prefill");
          this.fetchContentItems().catch(() => {});
        });
      }
    },
    selectedServiceProviderAccountCombination: {
      handler() {
        this.search = "";
        this.searchInRoot = "";
        this.$nextTick(() => {
          // console.log("watcher for selectedServiceProviderAccountCombination");
          this.fetchContentItems().catch(() => {});
        });
      }
    },
    showOnly: {
      // immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            // console.log("watcher for showOnly");
            this.fetchContentItems().catch(() => {});
          });
        }
      }
    },
    searchOptions: {
      // immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            // console.log("watcher for searchOptions");
            this.fetchContentItems().catch(() => {});
          });
        }
      }
    },
    pageSizeToRequest: {
      handler(newVal) {
        window.localStorage.setItem("studio7-pagination-pageSize", newVal);
        EventBus.$emit("page-size-changed", newVal); // required to sync page size in callflow editor syslog
        this.$nextTick(() => {
          // console.log("page size change");
          this.fetchContentItems().catch(() => {});
        });
      }
    },
    currentPage: {
      handler() {
        this.$nextTick(() => {
          // console.log("watcher for currentPage");
          this.fetchContentItems().catch(() => {});
          // commented out see ticket description STUD-5401
          //EventBus.$emit("uncheck-all-items");
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-toolbar {
  margin: 20px 0;

  .page-toolbar-row {
    display: flex;
    align-items: center;

    .el-form-item {
      margin-bottom: 0;
    }
  }
}
::v-deep .el-select {
  input {
    height: 44px;
    border-color: #f5f5f8;
    color: #a0a8b5;
  }

  //width: 90px;
  .el-input.is-focus .el-input__inner {
    border-color: var(--theme-color);
  }

  .el-input__inner:hover {
    border-color: var(--theme-color);
  }

  .el-select-dropdown__item.selected {
    color: var(--theme-color);
  }

  .el-input__inner:focus {
    border-color: var(--theme-color);
  }
}

::v-deep .content-view-switcher {
  .el-radio-button__inner {
    border-color: transparent !important;
    font-size: 20px;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: var(--theme-color);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}
</style>
