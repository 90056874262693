<script>
export default {
  name: "ContentIntervalUpdater",
  props: {
    autoRefreshContent: {
      default: false,
      type: Boolean
    },
    refreshInterval: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      contentLoader: null,
      destroyed: false
    };
  },
  methods: {
    contentScheduler() {
      if (this.autoRefreshContent && !this.destroyed) {
        // console.log("auto refresh");
        this.fetchContentItems(true)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.contentLoader =
              this.autoRefreshContent &&
              setTimeout(this.contentScheduler, this.refreshInterval);
          });
      }
    },
    triggerScheduler() {
      this.contentLoader ? clearTimeout(this.contentLoader) : null;
      if (this.mounted && this.autoRefreshContent) {
        this.contentScheduler();
      }
    }
  },

  beforeDestroy() {
    this.contentLoader ? clearTimeout(this.contentLoader) : null;
    this.destroyed = true;
  },

  watch: {
    autoRefreshContent: {
      immediate: true,
      handler: "triggerScheduler"
    },
    mounted: {
      immediate: true,
      handler: "triggerScheduler"
    }
  }
};
</script>
